import React, { FunctionComponent, useEffect, useState } from "react";
import { useQuery } from '@apollo/client';

import { GET_POSTS } from '../graphql/posts/get-posts';
import SEO from "../components/seo/seo"
import Header from '../components/header/header';
import BlogCard from "../components/cards/blog/blog-card";
import Loading from "../components/loading/loading";

const BlogPage: FunctionComponent = () => {
  const [ posts, setPosts ] = useState<any>([]);
  const { loading, data: postData } = useQuery(GET_POSTS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (postData) {
      const blogs = postData.posts.edges.filter(
        (blog) => blog.node.Posts.postType === "Blog",
      );
      setPosts(blogs);
    }
  }, [postData]);

  const BlogCards = [];
  for (const [index, value] of posts.entries()) {
    BlogCards.push(
      <div key={index} className="col-12 col-sm-6 col-md-4 col-xl-3 mb-3">
        <BlogCard blog={value.node} />
      </div>
    )
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <SEO title="Blogs" />
          <Header
            title="Blogs" 
          />
          <div className="container-fluid my-3 content">
            <div className="row">
              <div className="col">
                <div className="row align-items-top">
                  { BlogCards.length > 0 ? (
                    BlogCards
                  ) : (
                    <p>No blogs.</p>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default BlogPage
