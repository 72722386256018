import React, { FunctionComponent, useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, Chip, Typography } from '@material-ui/core';
import BlogCardStyle from './blog-card-style';
import { Link } from 'gatsby';

export interface BlogCardProps {
  blog: any;
}

const BlogCard: FunctionComponent<BlogCardProps> = ({
  blog,
}: BlogCardProps) => {
  const [ categories, setCategories ] = useState<any>([]);
  const [ formattedDate, setFormattedDate ] = useState<any>([]);

  useEffect(() => {
    if (blog.date) {
      const date = new Date(blog.date);
      setFormattedDate(date.toLocaleDateString());
    }
    if (blog.categories.nodes) {
      setCategories(blog.categories.nodes);
    }
  }, [blog])

  const categoryChips = [];
  for (const [index, value] of categories.entries()) {
    categoryChips.push(
      <Chip color="secondary" key={value.id} label={value.name} size="small" />
    )
  };

  return (
    <BlogCardStyle>
      <Link to={`/blog/post/?id=${blog.databaseId}`} state={{ id: blog.databaseId }}>
        <Card className="card">
          {blog.featuredImage ? (
            <CardMedia
              className="card-image"
              image={blog.featuredImage.node.sourceUrl}
              title={blog.featuredImage.node.altText}
            />
          ) : null }
          <div className="mt-3 pt-3 pr-3 pb-3">
            <Typography gutterBottom variant="h5" component="h2" className="title">
              {blog.title}
            </Typography>
          </div>
          <CardContent className="pt-0">
            <Typography variant="subtitle2" className="pb-3">
              {formattedDate}
            </Typography>
            {categoryChips ? (
              categoryChips
            ) : null}
          </CardContent>
        </Card>
      </Link>
    </BlogCardStyle>
  );
};

export default BlogCard
